<template>
  <v-container fluid class="thankyou-container">
    <v-row justify="center">
      <v-col cols="12" sm="10" md="8" lg="6" class="text-center">
        <h2 class="thankyou-heading">Success! Your Ultimate Meta Ads & WhatsApp Sales Guide is on Its Way!</h2>
        <h4 class="thankyou-subheading"><span class="highlighted-text">Check your inbox to access your free course.</span></h4>
        <p class="thankyou-text">
          Thank you for signing up for our Ultimate Meta Ads and WhatsApp Sales Guide! Your free course is on its way to your inbox. You'll find a link to access the course in the email.
        </p>
        <p class="thankyou-text">
          Don't forget to check your email and get started on your journey to sales success!
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {},
};
</script>

<style scoped>
.thankyou-container {
  padding-top: 64px;
  padding-bottom: 64px;
  background-color: rgba(255, 255, 255, 1);
}

.thankyou-heading {
  color: rgba(0, 0, 0, 1);
  font-size: 32px;
  line-height: 120%;
  font-family: "Inter", sans-serif;
}

.thankyou-subheading {
  margin-top: 16px;
  color: rgba(0, 0, 0, 1);
  font-size: 24px;
  line-height: 150%;
  font-family: "Inter", sans-serif;
}

.highlighted-text {
  color: #FFA726;
  font-weight: bold;
}

.thankyou-text {
  margin-top: 16px;
  color: rgba(0, 0, 0, 1);
  font-size: 18px;
  line-height: 150%;
  font-family: "Inter", sans-serif;
}
</style>
