import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCIkYV7N9eVV06G7qI7ZHzAbVHepcpXKWY",
    authDomain: "ilutorads-9fee6.firebaseapp.com",
    projectId: "ilutorads-9fee6",
    storageBucket: "ilutorads-9fee6.appspot.com",
    messagingSenderId: "526520249026",
    appId: "1:526520249026:web:4879609ea7a5b97a6a295b",
    measurementId: "G-LJEJNTRM1B"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

export default firebase;
export { db };