<template>
  <v-container fluid class="contact-container">
    <v-row justify="center">
      <v-col cols="12" sm="10" md="8" lg="6" class="text-center">
        <h2 class="contact-heading">You're Almost There!</h2>
<p class="contact-subheading">
  To claim your FREE Ultimate Meta Ads & WhatsApp Sales Guide!, simply provide your name and email address below.
  <span class="highlighted-text">After submitting, you'll receive an email with a link to access the course.</span>
  We value your privacy and promise never to send you unwanted emails or flood your inbox with irrelevant content.
</p>

        <v-form ref="form" @submit.prevent="submitForm">
          <v-text-field
            label="Name"
            placeholder="Your full name"
            outlined
            v-model="fullName"
            :rules="nameRules"
            required
          ></v-text-field>
          <v-text-field
            label="Email"
            placeholder="me@company.com"
            outlined
            v-model="email"
            :rules="emailRules"
            required
          ></v-text-field>
         <v-btn type="submit" color="black" dark class="send-message-btn" :disabled="loading">
  <span v-if="loading">
    <v-progress-circular indeterminate color="blue" size="24" class="mr-2"></v-progress-circular>
  </span>
  <span v-else>
    Submit
  </span>
</v-btn>

        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { db } from '@/firebase';

export default {
  data() {
    return {
      fullName: '',
      email: '',
      loading: false,
      nameRules: [(v) => !!v || 'Name is required'],
      emailRules: [
        (v) => !!v || 'Email is required',
        (v) => /.+@.+/.test(v) || 'Email must be valid',
      ],
    };
  },
  methods: {
async submitForm() {
  if (this.$refs.form.validate()) {
    this.loading = true; // Add this line
    try {
      await db.collection('ilutorad-db').add({
        fullName: this.fullName,
        email: this.email,
      });
      this.$router.push('/Metathankyou');
    } catch (error) {
      console.error('Error adding document: ', error);
    } finally {
      this.loading = false; // Add this line
    }
  }
},
  },
};
</script>


<style scoped>
.contact-container {
  padding-top: 64px;
  padding-bottom: 64px;
  background-color: rgba(255, 255, 255, 1);
}

.contact-heading {
  color: rgba(0, 0, 0, 1);
  font-size: 32px;
  line-height: 120%;
  font-family: "Inter", sans-serif;
}

.contact-subheading {
  margin-top: 16px;
  color: rgba(0, 0, 0, 1);
  font-size: 18px;
  line-height: 150%;
  font-family: "Inter", sans-serif;
}

.send-message-btn {
  font-size: 12px;
  line-height: 140%;
  font-family: "Inter", sans-serif;
}

.highlighted-text {
  font-weight: bold;
    color: #FFA726;
}



</style>

