<template>
  <v-container fluid class="background-color py-4">
    <v-row justify="center">
      <v-col cols="12" sm="10" md="8" lg="6">
       <v-row class="text-center mb-4">
    <v-col>
      <h1 class="display-1 font-weight-bold text-heading" :style="{color: 'white'}">
        <span class="congratulations">Edit PowerPoint Templates Like a Pro!</span> 🎉
      </h1>
      <h1 class="display-1 font-weight-bold text-heading" :style="{color: 'white'}">
        <span class="congratulations">Bonus:</span> Get 400+ stunning PowerPoint Templates for free!
      </h1>
      </v-col>
  </v-row>

        <v-row>
          <v-col cols="12">
        <v-card>
          <div class="video-wrapper">
            <iframe
  src="https://player.vimeo.com/video/829153193?autoplay=1"
  frameborder="0"
  allow="autoplay; fullscreen; picture-in-picture"
  allowfullscreen
></iframe>
          </div>

        </v-card>

          </v-col>
        </v-row>
      </v-col>
    </v-row>

   <v-row justify="center">
    <v-col cols="12" md="12" lg="12" class="inner-container">
      <div class="content">
        <h1 class="title mb-4 custom-h2" :style="{color: '#131921'}">I will show you how to edit any PowerPoint template for your presentations, advert videos, and explainer or short videos.</h1>
        <p class="description custom-p">
          Good presentations and videos are key to grabbing people's attention and conveying your message. With my 'Edit PowerPoint Templates Like a Pro' course, you'll learn how to enhance your communication skills by creating visually appealing presentations and videos using PowerPoint templates. And as a bonus, you'll receive over 400 premium PowerPoint templates to help you communicate your message with style and confidence.</p>
      </div>
    </v-col>
  </v-row>

   <v-row>
          <v-col cols="12" class="text-center mb-5">
            <v-btn color="orange accent-3" @click="proceed" class="white--text">
              Sign Me Up
            </v-btn>
          </v-col>
        </v-row>



        <v-row justify="center mb-5">
      <v-col cols="12" sm="10" md="8" lg="6">
        <v-row class="text-center mb-4">
    <v-col>
      <h2 class="headline text-heading" :style="{color: 'white'}">
        Check out a preview of one of the video lessons and see how simple and efficient it is to change a PowerPoint templates video.
      </h2>
      </v-col>
  </v-row>

        <v-row>
          <v-col cols="12">
        <v-card>
          <div class="video-wrapper">
            <iframe
              src="https://player.vimeo.com/video/829154600"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <!-- Rest of your card content -->
        </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>


  <v-row justify="center">
  <v-col cols="12" md="12" lg="12" class="inner-container">
    <div class="content">
      <h1 class="title custom-h1" :style="{color: '#FFA726'}"> Who Can Benefit From This Course</h1>
    </div>
  </v-col>
</v-row>



<v-row justify="center">
  <v-col cols="12" class="inner-container">
    <v-row class="metric-row">

      <v-col cols="12" md="4" class="metric-col">
        <div class="metric">
          <span class="caption">Business Owners 🏢</span>
          <div class="description2">Business owners can enhance their marketing efforts by creating powerful presentations and advert videos that effectively showcase products and services, attracting more customers and growing the business.</div>
        </div>
      </v-col>
      <v-col cols="12" md="4" class="metric-col">
  <div class="metric">
    <span class="caption">Employed Professionals 👩‍💼👨‍💼</span>
    <div class="description2">Impress your colleagues and supervisors with top-notch presentations during meetings and projects, showing your expertise and dedication to your work.</div>
  </div>
</v-col>
      <v-col cols="12" md="4" class="metric-col">
        <div class="metric">
          <span class="caption">Marketing Professionals 📊</span>
          <div class="description2">You'll be able to produce compelling presentations and promotional videos that effectively promote products and services, helping your company stand out from the competition and increase sales.</div>
        </div>
      </v-col>

      <v-col cols="12" md="4" class="metric-col">
  <div class="metric">
    <span class="caption">Public Speakers 🎤</span>
    <div class="description2">Public speakers can develop engaging presentations that captivate their audience and enhance their message, making their speeches more memorable and effective.</div>
  </div>
</v-col>

      <v-col cols="12" md="4" class="metric-col">
        <div class="metric">
          <span class="caption">Teachers 👩‍🏫👨‍🏫</span>
          <div class="description2">Teachers can make enjoyable and interactive lessons for students by using captivating presentations that keep them interested, promote better understanding, and make learning more fun.</div>
        </div>
      </v-col>
        <v-col cols="12" md="4" class="metric-col">
        <div class="metric">
          <span class="caption">Students 🎓</span>
          <div class="description2">As a student, you will learn to create impressive school project presentations that catch the attention of leactures and classmates, helping you stand out.</div>
        </div>
      </v-col>
      <v-col cols="12" md="4" class="metric-col">
        <div class="metric">
          <span class="caption">Freelancers 💻</span>
          <div class="description2">By acquiring new skills in PowerPoint templates editing, you can expand your service offerings, helping clients with their presentations and videos, and ultimately grow your freelance business.</div>
        </div>
      </v-col>

<v-col cols="12" md="4" class="metric-col">
  <div class="metric">
    <span class="caption">Pastors ⛪</span>
    <div class="description2">Pastors can craft captivating presentations for church sermons and events that resonate with their congregation, inspiring growth and a strong sense of community.</div>
  </div>
</v-col>
<v-col cols="12" md="4" class="metric-col">
  <div class="metric">
    <span class="caption">Content creators 📹</span>
    <div class="description2">If you make content, this course will help you make nice-looking presentations, advert videos, and explainer videos. With PowerPoint templates, you can make your content faster and better, helping you stand out and connect with your audience.</div>
  </div>
</v-col>



</v-row>
</v-col>
</v-row>


  <v-row justify="center" class="standout-section">
    <v-col cols="12" md="12" lg="12" class="inner-container">
      <div class="content">
        <h1 class="title mb-4 standout-title">If You Need This Course and Templates, Act Fast: Limited Time Offer 🚨⏳</h1>
        <div class="timer standout-timer">{{ formattedTime }}</div>
        <p class="description custom-p standout-description">
          Enroll now for just <span class="standout-text">3,000 Naira</span> and get instant access to the lessons along with a bonus of 400+ Premium PowerPoint Templates! Hurry, this special offer is available until the timer hits zero, after which the price will increase by 80%.</p>
      </div>
    </v-col>
  </v-row>




  <!-- <v-row justify="center mt-5 mb-2">
    <v-col cols="12" md="12" lg="12" class="d-flex justify-center">
      <div class="content">
        <h1 class="title-white">🚀 By signing up, you'll gain access to exclusive content that covers:</h1>
      </div>
    </v-col>
  </v-row> -->


 <v-row justify="center">
    <v-col cols="12" md="12" lg="12" class="inner-container">
      <div class="content">
        <h1 class="title custom-h1" :style="{color: '#131921'}"> Course Highlights:</h1>
       </div>
    </v-col>
  </v-row>


  <v-row justify="center">

  <v-col cols="12" class="inner-container">



<v-row class="metric-row">
  <v-col cols="12" md="3" class="metric-col">
    <div class="metric">
      <div class="description2">⭐ Learn simple ways to change templates, making nice presentations that share your message clearly.</div>
    </div>
  </v-col>
  <v-col cols="12" md="3" class="metric-col">
    <div class="metric">
      <div class="description2">⭐ Find out how to easily change videos, pictures, and text with your own to make a presentation that fits your requirements.</div>
    </div>
  </v-col>
  <v-col cols="12" md="3" class="metric-col">
    <div class="metric">
      <div class="description2">⭐ See how to change animations and movements in templates, making your presentations look more professional and fun.</div>
    </div>
  </v-col>
  <v-col cols="12" md="3" class="metric-col">
    <div class="metric">
      <div class="description2">⭐ Watch a real case study example of how to edit a PowerPoint template for an advert video, showing the easy steps to make a template fit your needs from start to finish.</div>
    </div>
  </v-col>
</v-row>

</v-col>
</v-row>



 <v-row justify="center">
    <v-col cols="12" md="12" lg="12" class="inner-container">
      <div class="content">
        <div class="text-center">
          <h1 class="title">Enroll Now and Get Instant Access to 400+ Premium PowerPoint Templates!</h1>
          <v-btn color="orange accent-3" @click="proceed" class="white--text mt-4 mb-10">
            Yes, Sign Me Up!
          </v-btn>
        </div>
      </div>
    </v-col>
  </v-row>

      <v-row class="text-center mb-4">
    <v-col>
      <h2 class="headline text-heading" :style="{color: 'white'}">
       Why Are Good Presentations, Advert Videos, and Explainer Videos Essential?</h2>
      </v-col>
  </v-row>

    <v-row>

      <v-col cols="12" sm="6" md="4" lg="3" xl="3">
        <v-card class="d-flex flex-column card-height mx-auto hover-elevation" elevation="5" max-width="400">
          <div class="image-container">
            <v-img src="@/assets/PPTAudienceAttentio.png" height="100%" alt="Presentation image"></v-img>
          </div>
          <v-card-title class="truncate-title">Capture Your Audience's Attention</v-card-title>
          <v-card-text>
            <div>Engaging visual content captures your audience's attention and keeps them focused on your message.
           </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" xl="3">
        <v-card class="d-flex flex-column card-height mx-auto hover-elevation" elevation="5"  max-width="400">
          <div class="image-container">
            <v-img src="@/assets/PPTCommunicateEffectively.png" height="100%" alt="Presentation image"></v-img>
          </div>
          <v-card-title class="truncate-title">Communicate Effectively</v-card-title>
          <v-card-text>
            <div>Well-designed presentations, advertisement videos, and explainer videos help you convey complex information clearly and concisely, ensuring your audience understands and retains your message.
           </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" xl="3">
        <v-card class="d-flex flex-column card-height mx-auto hover-elevation" elevation="5" max-width="400">
          <div class="image-container">
            <v-img src="@/assets/PPTProfessionalImage.png" height="100%" alt="Presentation image"></v-img>
          </div>
          <v-card-title class="truncate-title">Enhance Your Professional Image</v-card-title>
          <v-card-text>
            <div>High-quality visual content reflects positively on your personal brand, showcasing your professionalism and dedication to your work.
           </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" xl="3">
        <v-card class="d-flex flex-column card-height mx-auto hover-elevation" elevation="5" max-width="400">
          <div class="image-container">
            <v-img src="@/assets/PPTStandOut.png" height="100%" alt="Presentation image"></v-img>
          </div>
          <v-card-title class="truncate-title">Stand Out from the Crowd</v-card-title>
          <v-card-text>
            <div>Memorable presentations, advertisement videos, and explainer videos set you apart from the competition, making you more memorable and increasing your chances of success.
           </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>


     <v-row justify="center">
    <v-col cols="12" md="12" lg="12" class="inner-container">
      <div class="content">
        <h1 class="title custom-h1" :style="{color: '#131921'}"> The Amazing Bonus: Unlock the Full Potential of PowerPoint Templates 🎁</h1>
       </div>
    </v-col>
  </v-row>

    <v-row justify="center">

  <v-col cols="12" class="inner-container">



    <v-row class="metric-row">
      <v-col cols="12" md="3" class="metric-col">
      <div class="metric">
        <div class="value">Ready-to-use Designs</div>
        <div class="description">The PowerPoint templates bonus offers you over 400 professionally designed layouts, saving you time and effort while creating stunning presentations, advert videos, and explainer videos.</div>
      </div>
    </v-col>
    <v-col cols="12" md="3" class="metric-col">
      <div class="metric">
        <div class="value">Customizable Content</div>
        <div class="description">With these templates, you can easily edit and adapt the designs to suit your specific needs, ensuring a unique and personalized touch.</div>
      </div>
    </v-col>
    <v-col cols="12" md="3" class="metric-col">
      <div class="metric">
        <div class="value"> Effortless Speed and Consistency</div>
        <div class="description">The bonus PowerPoint templates facilitate quick and efficient content creation while maintaining a uniform look and feel across your presentation, advert video, or explainer video, enabling you to captivate your audience with minimal effort and time.</div>
      </div>
    </v-col>
    <v-col cols="12" md="3" class="metric-col">
      <div class="metric">
        <div class="value">Access to Professional Elements</div>
        <div class="description">Elevate your content with high-quality graphics, icons, and other design elements included in the templates.</div>
      </div>
    </v-col>
    </v-row>
  </v-col>
</v-row>



 <v-row justify="center">
    <v-col cols="12" md="12" lg="12" class="inner-container">
      <div class="content">
        <div class="text-center">
          <h1 class="title">Enroll in the course today and unlock this amazing bonus!</h1>
          <v-btn color="orange accent-3" @click="proceed" class="white--text mt-4 mb-10">
            Yes, Sign Me Up!
          </v-btn>
        </div>
      </div>
    </v-col>
  </v-row>

  </v-container>


</template>

<script>
/* global fbq */
import firebase from "@/firebase";

export default {



data() {
    return {
      timerValue: 0,
      formattedTime: "", // Add formattedTime data property
    };
  },
  created() {
    this.fetchTimerValue(); // Fetch timer value on page load
  },
   methods: {
    proceed() {
        this.$router.push('/pptpayment');
       fbq('track', 'pptCourseSignUpClicked');

    },
    fetchTimerValue() {
  // Fetch timer value from Firestore and initialize countdown timer
  firebase
    .firestore()
    .collection("countdown")
    .limit(1)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        // 1. Receive a timestamp value from Firestore
        const endDate = doc.data().EndDate;

        // 2. Subtract it from the current timestamp
        const timeStampValue = endDate.toMillis(); // Convert the Firestore timestamp to milliseconds
        const differenceInMilliseconds = timeStampValue - Date.now();

        // 3. Convert the result to whole number minutes as a string
        const differenceInMinutes = Math.floor(differenceInMilliseconds / 1000);
        this.timerValue = differenceInMinutes.toString();


        //this.timerValue = doc.data().timer;
        this.startCountdownTimer();
      });
    })
    .catch((error) => {
      console.error("Error fetching timer value: ", error);
    });
},
startCountdownTimer() {
      const formatTime = (time) => {
        const days = Math.floor(time / 86400);
        const hours = Math.floor((time % 86400) / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = time % 60;
        return `${days}Day(s) : ${hours.toString().padStart(2, "0")}Hours : ${minutes.toString().padStart(2, "0")}Minutes : ${seconds.toString().padStart(2, "0")}Seconds`;
      };

      const countdown = () => {
        if (this.timerValue > 0) {
          this.timerValue--;
          this.formattedTime = formatTime(this.timerValue);
        } else {
          clearInterval(interval);
        }
      };

      this.formattedTime = formatTime(this.timerValue);
      const interval = setInterval(countdown, 1000);
    },

  },

     mounted() {
    this.$fb.track('PageView');
  },

};
</script>

<style scoped>
.background-color {
  background-color: rgba(19, 25, 33, 1);
}

.truncate-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 50px;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
}

  .mb-10 {
    margin-bottom: 10px;
  }

  .card-height {
  height: 400px;
}

.image-container {
  position: relative;
   height: 200px;
  overflow: hidden;
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.hover-elevation {
  transition: box-shadow 0.3s ease-in-out;
}

.hover-elevation:hover {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
}


.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.caption-text {
  margin-bottom: 2px;
  text-align: center;
  background-color: rgba(19, 25, 33, 1);
  padding: 15px;
  border-radius: 5px;
}

.standout-section {
    background-color: #f2f2f2;
    padding: 30px 0;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .standout-title {
    color: #131921;
    font-size: 32px !important; /* Change to your desired font size */
  font-weight: bold !important;
  }

  .standout-timer {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 15px;
    color: rgba(233, 136, 98, 1);
  }

  .standout-description {
    font-size: 1.1rem;
    color: #333;
  }



.inner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(19, 25, 33, 1);
  padding: 5px;

  background-color: rgba(245, 247, 250, 1);
  padding: 24px 24px;

}

.inner-container2 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(19, 25, 33, 1);
  padding: 5px;

  background-color: rgba(245, 247, 250, 1);
  padding: 5px 5px;

}

.timer {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
  }

.metric-row {
  row-gap: 24px;
}
.metric-col {
  padding: 0 12px;
}
.metric {
  height: 100%;
}

.content {
  width: 100%;
  max-width: 896px;
  text-align: center;
}

  .congratulations {
    color: #FFA726;
  }

  .course-title {
    font-size: 2rem;
    font-weight: bold;
    color: #FFA726;
  }


.inner-container .content .custom-h1 {
  font-size: 32px !important; /* Change to your desired font size */
  font-weight: bold !important;
  color: #FFA726 !important;
}

.inner-container .content .custom-h2 {
  font-size: 32px !important; /* Change to your desired font size */
  font-weight: bold !important;
}

.inner-container .content .custom-p {
  font-size: 18px !important; /* Change to your desired font size */
}

.congratulations {
  color: #FFA726; /* Change to your desired color */
}


.title {
 color: rgba(19, 25, 33, 1);
  font-size: 60px;
  line-height: 120.00000476837158%;
  letter-spacing: 0%;
  font-family: "Inter", sans-serif;
  font-weight: bold;
}


.title-white {
  color: #ffffff;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.2;
  margin: 0;
}

.description {
  margin-top: 24px;
  color: white;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0%;
  font-family: "Inter", sans-serif;
}

.description2 {
  margin-top: 10px;
  color: black;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0%;
  font-family: "Inter", sans-serif;
  padding-left: 24px;
  text-indent: -24px;
}

.get-started-btn {
  margin-top: 24px;
  background-color: rgba(233, 136, 98, 1);
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  line-height: 139.9999976158142%;
  letter-spacing: 0%;
  font-family: "Inter", sans-serif;
}




.metric-row {
  justify-content: center;
  text-align: center;
}

.metric-col {
  padding: 0 2px;
  margin-bottom: 20px;
}

.metric {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.value {
  color: rgba(33, 33, 33, 1);
  font-size: 20px;
  font-weight: bold;
  line-height: 150%;
  letter-spacing: 0%;
  font-family: "Inter", sans-serif;
}
 .standout-text {
    font-weight: bold;
    color: rgba(233, 136, 98, 1);
    font-size: 1.2rem;
     position: relative;
  display: inline-block;
  }

  .standout-text::before {
  content: "N";
  position: absolute;
  left: -1px;
  top: 0;
  color: inherit;
  font-weight: bold;
  z-index: -1;
  text-shadow: 1px 0px 0px currentColor, -1px 0px 0px currentColor;
}

.description {
  margin-top: 4px;
  color: rgba(33, 33, 33, 1);
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0%;
  font-family: "Inter", sans-serif;
}

.caption {
  font-weight: bold;
   font-size: 18px !important;
}

.course-title {
  font-size: 1.5rem; /* Adjust the font size as needed */
  font-weight: bold;
  color: #FFA726;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* Media query for small screens */
@media screen and (max-width: 600px) {
  .video-wrapper iframe {
    width: 99%;
  }

 h1.text-heading {
    font-size: 22px !important; /* Adjust this value to your preference */
    line-height: 120.00000476837158% !important;
  }
  h2.text-heading {
    font-size: 20px !important; /* Adjust this value to your preference */
    line-height: 120.00000476837158% !important;
  }
}
</style>
