<template>
<div id="app" class="app-layout">
  <v-app>
    <v-main class="main-layout">

        <router-view></router-view>

    </v-main>
      <Footer />
  </v-app>
</div>
</template>

<script>
import Footer from "./components/Footer.vue";
export default {
  name: 'App',
    components: {
    Footer,
  },
};
</script>

<style scoped>

.app-layout {
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: 100vh;
}
</style>


