import Vue from 'vue';
import Router from 'vue-router';
import Ad from './components/Ad.vue';
import AdForm from './components/Adform.vue';
import ThankYou from './components/Metathankyou.vue';
import PPTcourse from './components/PPTcourse.vue';
import PPTpayment from './components/PPTpayment.vue';
import Home from './components/Home.vue';


Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    { path: '/', component: Home },
    { path: '/fb_ad', component: Ad },
    { path: '/fbform', component: AdForm },
    { path: '/fbthankyou', component: ThankYou },
    { path: '/pptcourse', component: PPTcourse},
    { path: '/pptpayment', component: PPTpayment},

  ],
});