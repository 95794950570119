<template>
  <v-container fluid class="payment-container">
    <v-row justify="center">
      <v-col cols="12" sm="10" md="8" lg="6" class="text-center">
        <h2 class="payment-heading">Choose from two Convenient Payment options:</h2>

        <hr class="payment-separator">

        <div class="payment-option">
          <h4 class="payment-subheading">Pay with Paystack on the Course Page</h4>
          <p class="payment-text">
            For instant access to the lessons and bonus material, choose to pay with Paystack directly on the course page. After successful payment, you'll receive an email containing the link to download your bonus material and immediately gain access to the course content.
          </p>
           <v-btn color="orange" dark href="https://courses.ilutor.com/learning/cb173f02-7528-4021-a335-2e9560e1f647" target="_blank" @click="PPTtrackPayOnCoursePage">Pay on Course Page</v-btn>
        </div>

        <hr class="payment-separator">

        <div class="payment-option">
          <h4 class="payment-subheading">Bank Transfer</h4>
          <p class="payment-text">
            To pay via bank transfer, simply send N3,000 to the following account:
            <br><strong>Bank:</strong> GTB
            <br><strong>Account Name:</strong> ILTutor Education Center
            <br><strong>Account Number:</strong> 0618100926
            <br>After making the transfer, kindly send your proof of payment to our WhatsApp number - 08093958604. Once we receive and validate your payment, we will grant you access to the course and bonus.
          </p>
         <v-btn color="green" href="https://wa.me/2348093958604?text=Hi%2C%0AI%20have%20interest%20in%20the%20Powerpoint%20course%20offering.%20Just%20wanted%20to%20let%20you%20know%20that%20I%27ve%20made%20N3000%20payment." target="_blank">
  <v-icon left>fab fa-whatsapp</v-icon> Send Proof on WhatsApp
</v-btn>
        </div>
<!-- <hr class="payment-separator">

<div class="payment-option">
  <h4 class="payment-subheading">Pay with Paystack Link</h4>
  <p class="payment-text">
    You can also make your payment using the following Paystack link:
  </p>
  <v-btn color="orange" dark href="https://paystack.com/pay/edit_powerpoint_templates_like_a_pro" target="_blank" @click="PPTtrackPayWithPaystack">Pay with Paystack</v-btn>

  <p class="payment-text">
    After completing the payment, please send your proof of payment to our WhatsApp number - 08093958604. We will validate your payment and provide you with access to the course and bonus.
  </p>
  <v-btn color="green" href="https://wa.me/2348093958604?text=Hi%2C%0AI%20have%20interest%20in%20the%20Powerpoint%20course%20offering.%20Just%20wanted%20to%20let%20you%20know%20that%20I%27ve%20made%20N3000%20payment." target="_blank">
  <v-icon left>fab fa-whatsapp</v-icon> Send Proof on WhatsApp
</v-btn>
</div> -->

</v-col>
</v-row>
</v-container>
</template>

<script>
/* global fbq */
export default {
name: 'PaymentOptions',
methods: {
  PPTtrackPayWithPaystack() {
    // Add Facebook Pixel event tracking
    fbq('track', 'pptPayWithPaystackClicked');
  },
  PPTtrackPayOnCoursePage() {
    // Add Facebook Pixel event tracking
    fbq('track', 'pptPayOnCoursePageClicked');
  },
},
};
</script>

<style scoped>
.payment-container {
padding-top: 64px;
padding-bottom: 64px;
background-color: rgba(255, 255, 255, 1);
}

.payment-heading {
color: #000000;
font-size: 32px;
line-height: 120%;
font-family: "Inter", sans-serif;
}

.payment-subheading {
margin-top: 16px;
color: rgba(0, 0, 0, 1);
font-size: 24px;
line-height: 150%;
font-family: "Inter", sans-serif;
}

.payment-text {
margin-top: 16px;
color: rgba(0, 0, 0, 1);
font-size: 18px;
line-height: 150%;
font-family: "Inter", sans-serif;
}

.payment-separator {
margin: 32px 0;
border: none;
border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.payment-option {
margin-bottom: 32px;
}

.payment-button {
display: inline-block;
margin-top: 16px;
padding: 12px 24px;
background-color: #FFA726;
color: white;
font-size: 18px;
font-weight: bold;
text-decoration: none;
border-radius: 4px;
}

.payment-button:hover {
background-color: #FF9A00;
}
</style>
