<template>
  <v-footer color="rgba(19, 25, 33, 1)">
    <v-container fluid>
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="2" class="d-flex justify-center">
          <v-img
            :src="require('@/assets/courselogo.png')"
            aspect-ratio="4"
            max-width="140"
            contain
          ></v-img>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="2" class="d-flex justify-center">
          <div class="copyright">Copyright © 2023 ilutor</div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="12">
          <div class="disclaimers">
            <h3 class="disclaimer-caption">Disclaimer</h3>
            <p class="combined-disclaimer">*The content presented on this landing page is independently created and not associated with or officially supported by Meta or YouTube.</p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.copyright {
  color: white;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0%;
  font-family: "Inter", sans-serif;
}

.disclaimer-caption {
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  font-family: "Inter", sans-serif;
}

.combined-disclaimer {
  color: white;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0%;
  font-family: "Inter", sans-serif;
  text-align: center;
}
</style>
