<template>
  <div>
  </div>
</template>

<script>
export default {
  name: 'Redirect',
  mounted() {
    window.location.href = 'https://www.ilutor.com';
  },
};
</script>