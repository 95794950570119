<template>
  <v-container fluid class="background-color py-4">
    <v-row justify="center">
      <v-col cols="12" sm="10" md="8" lg="6">
       <v-row class="text-center mb-4">
    <v-col>
      <h1 class="display-1 font-weight-bold text-heading" :style="{color: 'white'}">
        <span class="congratulations">Congratulations!</span> 🎉 Master the Art of Generating WhatsApp Leads with my <span class="course-title">Ultimate Meta Ads & WhatsApp Sales Guide</span> 🚀
      </h1>
    </v-col>
  </v-row>

        <v-row>
          <v-col cols="12">
        <v-card>
          <div class="video-wrapper">
            <iframe
              src="https://player.vimeo.com/video/772876633"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <!-- Rest of your card content -->
        </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

      <v-row>
          <v-col cols="12" class="text-center mb-5">
            <v-btn color="orange accent-3" @click="proceed" class="white--text">
              Sign Me Up
            </v-btn>
          </v-col>
        </v-row>

   <v-row justify="center mb-3">
    <v-col cols="12" md="12" lg="12" class="inner-container">
      <div class="content">
        <h1 class="title mb-4 custom-h1" :style="{color: '#131921'}">Unlock the secrets to creating effective ads and capturing high-quality leads through WhatsApp with my free comprehensive course!</h1>
        <p class="description custom-p">
          This course is designed to teach you everything you need to know to excel in generating leads through WhatsApp.
        </p>
      </div>
    </v-col>
  </v-row>


  <v-row justify="center mb-2">
    <v-col cols="12" md="12" lg="12" class="d-flex justify-center">
      <div class="content">
        <h1 class="title-white">🚀 By signing up, you'll gain access to exclusive content that covers:</h1>
      </div>
    </v-col>
  </v-row>


     <v-row justify="center mb-2">
      <v-col cols="12" class="inner-container">
         <v-row class="metric-row">
    <v-col cols="12" md="3" class="metric-col">
      <div class="metric">
        <div class="value">📚 Introduction</div>
        <div class="description">Get a background understanding of Meta Ads and WhatsApp sales strategies.</div>
      </div>
    </v-col>
    <v-col cols="12" md="3" class="metric-col">
      <div class="metric">
        <div class="value">🎨 Crafting Ads</div>
        <div class="description">Discover how to craft compelling and high-converting ads that captivate your audience and generate leads, demonstrated through five engaging videos.</div>
      </div>
    </v-col>
    <v-col cols="12" md="3" class="metric-col">
      <div class="metric">
        <div class="value">🚀 Creating Ads</div>
        <div class="description">Learn the step-by-step process for setting up and launching successful ad campaigns targeting WhatsApp leads, explained in two informative videos.</div>
      </div>
    </v-col>
    <v-col cols="12" md="3" class="metric-col">
      <div class="metric">
        <div class="value">💬 Chatting Tips</div>
        <div class="description">Master the art of communication on WhatsApp, and convert your leads into customers with ease.</div>
      </div>
    </v-col>
  </v-row>
      </v-col>
    </v-row>

     <v-row justify="center mb-2">
    <v-col cols="12" md="12" lg="12" class="d-flex justify-center">
      <div class="content">
        <h1 class="title-white">Whether you're looking to create your own effective Facebook and Instagram ads or seeking to acquire a valuable skill to monetize by creating ads for others, this expert-led course has you covered. Empower yourself and take control of your advertising journey by learning from the best.</h1>
      </div>
    </v-col>
  </v-row>

 <v-row justify="center">
    <v-col cols="12" md="12" lg="12" class="inner-container">
      <div class="content">
        <div class="text-center">
          <h1 class="title">Ready to start your journey to sales success? Click the button below to proceed to the next step!</h1>
          <v-btn color="orange accent-3" @click="proceed" class="white--text mt-4 mb-10">
            Yes, Sign Me Up!
          </v-btn>
        </div>
      </div>
    </v-col>
  </v-row>


  </v-container>


</template>

<script>
export default {
   methods: {
    proceed() {
      this.$router.push('/fbform');
    },
  },

};
</script>

<style scoped>
.background-color {
  background-color: rgba(19, 25, 33, 1);
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
}

  .mb-10 {
    margin-bottom: 10px;
  }

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}



.inner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(19, 25, 33, 1);
  padding: 10px;
}

.content {
  width: 100%;
  max-width: 896px;
  text-align: center;
}

  .congratulations {
    color: #FFA726;
  }

  .course-title {
    font-size: 2rem;
    font-weight: bold;
    color: #FFA726;
  }


.inner-container .content .custom-h1 {
  font-size: 32px !important; /* Change to your desired font size */
}

.inner-container .content .custom-p {
  font-size: 18px !important; /* Change to your desired font size */
}

.congratulations {
  color: #FFA726; /* Change to your desired color */
}


.title {
 color: rgba(19, 25, 33, 1);
  font-size: 60px;
  line-height: 120.00000476837158%;
  letter-spacing: 0%;
  font-family: "Inter", sans-serif;
  font-weight: bold;
}


.title-white {
 color: white;
  font-size: 20px;
  line-height: 120.00000476837158%;
  letter-spacing: 0%;
  font-family: "Inter", sans-serif;
}

.description {
  margin-top: 24px;
  color: white;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0%;
  font-family: "Inter", sans-serif;
}

.get-started-btn {
  margin-top: 24px;
  background-color: rgba(233, 136, 98, 1);
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  line-height: 139.9999976158142%;
  letter-spacing: 0%;
  font-family: "Inter", sans-serif;
}


.inner-container {
  background-color: rgba(245, 247, 250, 1);
  padding: 64px 24px;
}

.metric-row {
  justify-content: center;
  text-align: center;
}

.metric-col {
  padding: 0 10px;
  margin-bottom: 20px;
}

.metric {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.value {
  color: rgba(33, 33, 33, 1);
  font-size: 20px;
  font-weight: bold;
  line-height: 150%;
  letter-spacing: 0%;
  font-family: "Inter", sans-serif;
}

.description {
  margin-top: 4px;
  color: rgba(33, 33, 33, 1);
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0%;
  font-family: "Inter", sans-serif;
}

/* Media query for small screens */
@media screen and (max-width: 600px) {
  .video-wrapper iframe {
    width: 99%;
  }

 h1.text-heading {
    font-size: 30px !important; /* Adjust this value to your preference */
    line-height: 120.00000476837158% !important;
  }
}
</style>
